const parseContexts = (ev) => {
  const ctxes = {}
  const ctxversions = {}
  const result = { ctxes, ctxversions }
  if (!ev.context) return result
  for (const ctx of ev.context) {
    if (ctx.schema.startsWith('iglu:be.vrt/context_')) {
      const [, context, , version] = ctx.schema.split('/')
      ctxes[context] = ctx.data
      ctxversions[context] = version
    }
  }
  return result
}

export default parseContexts
