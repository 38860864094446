import logger from 'loglevel'
import CEDDLSubscriber from './CEDDL/CEDDLSubscriber'
import EBAConfig from './EBAConfig/EBAConfig'
import EDDLPublisher from './EDDL/EDDLPublisher'
import GemiusSubscriber from './Gemius/GemiusSubscriber'
import SmartoctoSubscriber from './Smartocto/SmartoctoSubscriber'
import SnowplowSubscriber from './Snowplow/SnowplowSubscriber'

// upgrade digitalData.events array to pub-sub mechanism
window.digitalData = window.digitalData || {}
window.digitalData.pastEvents = window.digitalData.events || []
EDDLPublisher.install(window.digitalData.pastEvents)
window.digitalData.pastEvents = undefined

// install and expose the necessary subscribers
window.VRT = window.VRT || {}
window.VRT.EBA = window.VRT.EBA || {}

if (EBAConfig.ceddl_enabled) {
  CEDDLSubscriber.install()
  window.VRT.EBA.CEDDLSubscriber = CEDDLSubscriber
}

if (EBAConfig.snowplow_enabled) {
  SnowplowSubscriber.install()
  window.VRT.EBA.SnowplowSubscriber = SnowplowSubscriber
}

if (EBAConfig.gemius_enabled) {
  GemiusSubscriber.install()
  window.VRT.EBA.GemiusSubscriber = GemiusSubscriber
}

if (EBAConfig.smartocto_enabled) {
  SmartoctoSubscriber.install()
  window.VRT.EBA.SmartoctoSubscriber = SmartoctoSubscriber
}

// expose publisher and logger
window.VRT.EBA.EDDLPublisher = EDDLPublisher
window.VRT.EBA.logger = logger
window.VRT.EBA.startRemoteDebugging = EDDLPublisher.startRemoteDebugging
window.VRT.EBA.stopRemoteDebugging = EDDLPublisher.stopRemoteDebugging

// offer uninstall
window.VRT.EBA.uninstall = () => {
  try {
    EDDLPublisher.removeSubscribers()
    if (EBAConfig.ceddl_enabled) CEDDLSubscriber.uninstall()
    if (EBAConfig.snowplow_enabled) SnowplowSubscriber.uninstall()
    if (EBAConfig.gemius_enabled) GemiusSubscriber.uninstall()
    if (EBAConfig.smartocto_enabled) SmartoctoSubscriber.uninstall()
    EDDLPublisher.uninstall()
    window.EBA = undefined
  } catch (e) {
    logger.warn('EBA: failed to uninstall')
    logger.warn(e)
  }
}

setTimeout(() => {
  window.dispatchEvent(new CustomEvent('EBAReady2'))
}, 100)
