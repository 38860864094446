import logger from 'loglevel'
import CMPHelper from '../CMP/CMPHelper'
import EBAConfig from '../EBAConfig/EBAConfig'
import SSOHelper from '../SSO/SSOHelper'
import EDDLEnricher from './EDDLEnricher'

function EDDLPublisherFactory() {
  const stats = {
    eventsProcessed: 0,
  }

  // =========
  // privates
  // =========

  let waitsForCMP = 0
  let waitsForSSO = 0

  let eddlDebugger = null
  let remoteDebuggingCode = null

  const switches = {
    selectedForSampling: Math.random() < EBAConfig.sampling_odds,
  }

  const handleNewSubscriber = (subsc, events) => {
    try {
      events.subscribers.push(subsc)
      // playback the events we still have for the new subscriber
      for (const ev of events) {
        try {
          if (ev.event?.schema) {
            // only the proper events
            subsc(ev)
          }
        } catch (e) {
          logger.warn(
            `EBA: unhandled exception upon playback of event ${JSON.stringify(ev)} to subscriber ${subsc}}`,
          )
          logger.warn(e)
        }
      }
    } catch (e) {
      logger.warn(`EBA: Error handling subscription ${JSON.stringify(subsc)}`)
      logger.warn(e)
    }
  }
  const sendEvent = (ev, events) => {
    for (const subsc of events.subscribers) {
      try {
        subsc(ev)
      } catch (e) {
        logger.warn(
          `EBA: unhandled exception delivering event ${JSON.stringify(ev)} to subscriber ${subsc}}`,
        )
        logger.warn(e)
      }
    }
    return true
  }

  // returns true if the event should be stored, false otherwise
  const handleEvent = async (ev, events) => {
    try {
      if (ev.subscribe) {
        logger.info('EBA EDDLPublisher: handling a subscribe event')
        handleNewSubscriber(ev.subscribe, events)
        return false
      }
      if (ev.event?.schema) {
        // it's a proper event
        logger.info('EBA EDDLPublisher: handling a normal event')
        stats.eventsProcessed += 1
        // only send sampled eventtypes when this client is participating
        if (
          switches.selectedForSampling ||
          !EBAConfig.sampling_events.includes(ev.event.schema.split('/')[1])
        ) {
          const enrichedEvent = EDDLEnricher.enrichEvent(ev, {
            remoteDebuggingCode,
          })
          if (eddlDebugger) await eddlDebugger.debugEvent(ev)
          return sendEvent(enrichedEvent, events)
        }
        return false
      }
      // it's some legacy event
      return true
    } catch (e) {
      logger.warn(`EBA: Error handling event ${JSON.stringify(ev)}`)
      logger.warn(e)
      return true
    }
  }

  // enhance the digitalData events array with subscribers and push function
  const enhanceEventsArray = (newEvents, subscribers) => {
    // if already enhanced, abort
    if (newEvents.subscribers) return

    // install subscribers array
    const events = newEvents
    events.subscribers = subscribers

    // override push function to alert subscribers, store, and keep within size limits
    events.push = function push(...args) {
      for (const ev of args) {
        if (handleEvent(ev, events)) {
          // only store them if event handler says it is needed (true)
          Array.prototype.push.apply(this, [ev])
        }
      }
      // size check: getting too large?
      if (events.length > EBAConfig.eddl_maxSize) {
        const nextEvents = events.slice(EBAConfig.eddl_sliceTo)
        enhanceEventsArray(nextEvents, subscribers)
      }
      return window.digitalData.events.length // array push function should return length
    }

    window.digitalData.events = events
  }

  const stillWaitForDependencies = () => {
    waitsForCMP += 1
    waitsForSSO += 1
    if (
      waitsForCMP >= EBAConfig.eddl_cmpMaxAttempts &&
      waitsForSSO >= EBAConfig.eddl_ssoMaxAttempts
    )
      return false
    if (waitsForCMP < EBAConfig.eddl_cmpMaxAttempts && !CMPHelper.isCMPReady())
      return true
    if (waitsForSSO < EBAConfig.eddl_ssoMaxAttempts && !SSOHelper.isSSOReady())
      return true
    return false
  }

  const randomCode = () =>
    `${Math.random().toString(36)}00000000000000000`.slice(2, 7).toUpperCase()

  const getRemoteDebuggingKey = () => `remdebug_${EBAConfig.snowplow_appid}`

  const clearRemoteDebugging = () => {
    window.localStorage.removeItem(getRemoteDebuggingKey())
  }

  const restoreRemoteDebugging = () => {
    const remdebug = JSON.parse(
      window.localStorage.getItem(getRemoteDebuggingKey()),
    )
    if (remdebug?.code && remdebug.expiry && Date.now() < remdebug.expiry) {
      remoteDebuggingCode = remdebug.code
      logger.info(
        `EBA remote debugging resumed : use code ${remoteDebuggingCode} on https://t${EBAConfig.is_prod ? '' : '-stag'}.vrt.be/debugger/session/${remoteDebuggingCode}`,
      )
    } else {
      clearRemoteDebugging()
    }
  }

  const storeRemoteDebugging = (code, expiry) => {
    const value = { code, expiry }
    window.localStorage.setItem(getRemoteDebuggingKey(), JSON.stringify(value))
  }

  // =========
  // publics
  // =========
  const numSubscribers = () => window.digitalData.events.subscribers.length

  const removeSubscribers = () => {
    window.digitalData.events.subscribers = []
  }

  const install = (pastEvents) => {
    if (stillWaitForDependencies()) {
      logger.info('EBA EDDLPublisher: still waiting for CMP/SSO dependencies')
      setTimeout(() => install(pastEvents), EBAConfig.eddl_dependencyWaitMillis)
    } else {
      logger.info('EBA EDDLPublisher: proceeding...')
      try {
        logger.info('EBA EDDLPublisher: enhancing digitalData.events')
        enhanceEventsArray([], [])
        logger.info('EBA EDDLPublisher: done enhancing. digitalData is now:')
        logger.info(JSON.stringify(window.digitalData, null, 2))
        // 1. first process all past subscribe requests, make them skip the line
        for (const ev of pastEvents) {
          if (ev.subscribe) {
            logger.info('EBA EDDLPublisher: replaying a subscribe event')
            window.digitalData.events.push(ev)
          }
        }

        // 2. next process all past regular events, which will now also be sent to subscribers
        for (const ev of pastEvents) {
          if (!ev.subscribe) {
            logger.info('EBA EDDLPublisher: replaying a normal event')
            window.digitalData.events.push(ev)
          }
        }
      } catch (e) {
        logger.warn('EBA: failed to install EDDLPublisher')
        logger.warn(e)
      }
    }
  }

  const startLocalDebugging = () => {
    const s = document.createElement('script')
    s.src = EBAConfig.debugger_url
    document.body.appendChild(s)
  }

  const setDebugger = (debugr) => {
    eddlDebugger = debugr
  }

  const startRemoteDebugging = (knownCode) => {
    const code = knownCode || randomCode()
    const expiry = Date.now() + 14400000 // 4 hours
    storeRemoteDebugging(code, expiry)
    remoteDebuggingCode = code
    if (window.VRT.EBA.logger) window.VRT.EBA.logger.enableAll()
    logger.info(
      `EBA remote debugging activated : use code ${remoteDebuggingCode} or go straight to https://t${EBAConfig.is_prod ? '' : '-stag'}.vrt.be/debugger/session/${remoteDebuggingCode}`,
    )
    return remoteDebuggingCode
  }

  const stopRemoteDebugging = () => {
    remoteDebuggingCode = null
    clearRemoteDebugging()
    logger.info('EBA remote debugging deactivated')
  }

  const getRemoteDebuggingCode = () => remoteDebuggingCode

  const setTouchpointbrand = (touchpointbrand) => {
    EDDLEnricher.setTouchpointbrand(touchpointbrand)
  }

  const getTouchpointbrand = () => EDDLEnricher.getTouchpointbrand()

  const uninstall = () => {
    if (EBAConfig.ceddl_enabled) {
      window.digitalData = undefined
    } else {
      window.digitalData.events = undefined
    }
  }

  restoreRemoteDebugging()

  return {
    install,
    stats,
    switches,
    numSubscribers,
    removeSubscribers,
    startLocalDebugging,
    setDebugger,
    startRemoteDebugging,
    stopRemoteDebugging,
    getRemoteDebuggingCode,
    setTouchpointbrand,
    getTouchpointbrand,
    uninstall,
  }
}

export default EDDLPublisherFactory()
