import logger from 'loglevel'
import EBAConfig from '../EBAConfig/EBAConfig'

function CMPHelperFactory() {
  // =========
  // privates
  // =========
  let consents = null
  let subscribedToCMP = false

  // cfr https://documentation.sourcepoint.com/api/gdpr-tcf-v2-api/iab-__tcfapi-function/__tcfapi-getcustomvendorconsents-api#getcustomvendorconsents-response
  const hasConsent = (consentedPurposes, name) =>
    consentedPurposes.some((el) => el.name === name)

  const parseCMPData = (tcdata) => {
    consents = {}
    // only limited info in the basic __tcfapi call
    consents.consentstring = tcdata.tcString || 'empty'
    consents.consentnecessary = true
    // additional info needs an extra __tcfapi call for the VRT vendor ID
    window.__tcfapi(
      'getCustomVendorConsents',
      2,
      (vrtData) => {
        if (vrtData.consentedVendors.length > 0) {
          consents.consentfunctional = hasConsent(
            vrtData.consentedPurposes,
            'Functional',
          )
          consents.consentanalytics = hasConsent(
            vrtData.consentedPurposes,
            'Analytics',
          )
          consents.consentsocial = hasConsent(
            vrtData.consentedPurposes,
            'Social Media',
          )
          consents.consentpersonalisation =
            tcdata.purpose?.consents?.['4'] || false
        } else {
          consents.consentfunctional = false
          consents.consentanalytics = false
          consents.consentsocial = false
          consents.consentpersonalisation = false
        }
      },
      [EBAConfig.cmp_vrtVendorId],
    )
    // consentchoice calculated field
    if (
      consents.consentfunctional &&
      consents.consentanalytics &&
      consents.consentsocial &&
      consents.consentpersonalisation
    ) {
      consents.consentchoice = 'all'
    } else if (
      !(
        consents.consentfunctional ||
        consents.consentanalytics ||
        consents.consentsocial ||
        consents.consentpersonalisation
      )
    ) {
      consents.consentchoice = 'no'
    } else {
      consents.consentchoice = 'partial'
    }
    logger.debug('EBA CMPHelper: consents are now :')
    logger.debug(consents)
  }

  const asyncGetConsents = (tcdata, success) => {
    logger.debug('EBA CMPHelper : got called back by CMP')
    logger.debug(tcdata)
    logger.debug(success)
    if (
      (success && tcdata.eventStatus === 'tcloaded') ||
      (success && tcdata.eventStatus === 'useractioncomplete')
    )
      parseCMPData(tcdata)
  }

  const syncGetConsents = () => {
    try {
      if (isCMPReady()) {
        // subscribe to CMP for async updates, if not the case already
        if (!subscribedToCMP) {
          window.__tcfapi('addEventListener', 2, asyncGetConsents)
          subscribedToCMP = true
        }
      }
      return consents
    } catch (_e) {
      logger.warn('EBA CMPHelper: exception in syncGetConsents()')
      return false
    }
  }

  // =========
  // publics
  // =========

  const isCMPReady = () => {
    try {
      logger.debug('EBA CMPHelper: __tcfapi check')
      if (!window.__tcfapi) return false
      let ready = false
      window.__tcfapi('ping', 2, (pingReturn) => {
        ready = pingReturn.cmpLoaded
      })
      logger.debug(`EBA CMPHelper: __tcfapi ready ${ready}`)
      return ready
    } catch (_e) {
      logger.warn('EBA CMPHelper: exception in isCMPReady()')
      return false
    }
  }

  const getConsents = () => {
    if (!consents) syncGetConsents() // hopefully only needed once, async updates should take over
    return consents
  }

  return {
    isCMPReady,
    getConsents,
  }
}

export default CMPHelperFactory()
