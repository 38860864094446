const baseEBAConfig = {
  snowplow_enabled: true,
  ceddl_enabled: false,
  gemius_enabled: false,

  snowplow_eventMethod: 'beacon',
  snowplow_bufferSize: 1,
  snowplow_maxLocalStorageQueueSize: 200,
  snowplow_cookieSecure: true,
  snowplow_discoverRootDomain: false,
  snowplow_flushTimeout: 1000,
  snowplow_appSkipList: [],
  snowplow_aggressiveCleanup: false,

  eddl_maxSize: 500, // max size of digitalData.events before reducing
  eddl_sliceTo: -100, // reduce to this amount of most recent events
  eddl_cmpMaxAttempts: 6, // if we don't wait for the CMP, we might measure without a consent string. Note that events still can be pushed into digitalData.events in the mean time and none are lost
  eddl_ssoMaxAttempts: 6, // if we don't wait for SSO, we measure without a user context. Note that events still can be pushed into digitalData.events in the mean time and none are lost
  eddl_dependencyWaitMillis: 150,

  gemius_pageTrackingId: 'overrideStill',
  gemius_cmpMaxAttempts: 6, // if we don't wait for the CMP, Gemius defaults to using cookies.  While waiting, no events are lost unless the buffer rotates (cfr eddl_maxSize)
  gemius_cmpWaitMillis: 150,
  gemius_appSkipList: [],

  cmp_vrtVendorId: '6225ead6961680068409eae2',

  ceddl_initialPage: { channel: 'web' },

  sampling_odds: 0,
  sampling_events: [
    'media_bufferstart',
    'media_bufferend',
    'media_qualitychange',
    'media_error',
  ],

  in_app: false,

  fallback_touchpointbrand: 'vrt',
}

const baseNonProdEBAConfig = {
  ...baseEBAConfig,
  is_prod: false,
  environment: 'unknown',
  snowplow_encodeBase64: false,
  snowplow_collectorUrl: 'https://c.stag.vrt.be',
  debugger_url: 'https://t-stag.vrt.be/latest/vrtebadebug.min.js',
  schemas_url: 'https://t-stag.vrt.be/latest/schemas/',
}

const baseProdEBAConfig = {
  ...baseEBAConfig,
  is_prod: true,
  environment: 'prod',
  snowplow_encodeBase64: true,
  snowplow_collectorUrl: 'https://c.vrt.be',
  debugger_url: 'https://t.vrt.be/latest/vrtebadebug.min.js',
  schemas_url: 'https://t.vrt.be/latest/schemas/',
}

const baseVRTMAXConfig = {
  ceddl_enabled: true,
  gemius_enabled: true,
  in_app: !!(typeof window !== 'undefined' && window.EBAMobile),
  gemius_pageTrackingId: 'bOtF1lg_vbRDmNTulrlMLrQGnH7_v_RXdTiiur3wymP.n7',
  ceddl_initialPage: {
    channel: 'web',
    page_technology: 'single page',
    brand_media: 'vrt nu',
    brand_technology: 'vrt nu',
  },
  fallback_touchpointbrand: 'vrt max',
}

const baseVRTMAXTVConfig = {
  ceddl_enabled: true,
  gemius_enabled: true,
  gemius_pageTrackingId: 'bO3glJg2v2de8cSvxf4A9nXoPzHZhvrZsPW2_JZQUUz.87',
  ceddl_initialPage: {
    channel: 'tv app',
    page_technology: 'single page',
    brand_media: 'vrt nu',
    brand_technology: 'vrt nu',
  },
  snowplow_aggressiveCleanup: true,
  fallback_touchpointbrand: 'vrt max',
}

const baseChromecastConfig = {
  gemius_enabled: false,
  ceddl_enabled: false,
  snowplow_cookieDomain: null,
  snowplow_discoverRootDomain: true,
  platform: 'chromecast',
  platformvendor: 'google',
  eddl_cmpMaxAttempts: 0,
}

const baseNWSConfig = {
  snowplow_enabled: true,
  ceddl_enabled: true,
  gemius_enabled: true,
  in_app: !!(typeof window !== 'undefined' && window.Flutter),
  snowplow_appSkipList: ['iglu:be.vrt/page_impression'],
  gemius_appSkipList: ['iglu:be.vrt/page_impression'],
  platformtechnology:
    typeof window !== 'undefined' && window.VRTNWSNext ? 'nextjs' : 'aem',
  fallback_touchpointbrand: 'vrt nws',
}

const baseNWSNWSNWSConfig = {
  snowplow_enabled: true,
  ceddl_enabled: true,
  gemius_enabled: true,
  platformtechnology: 'nextjs',
  fallback_touchpointbrand: 'nwsnwsnws',
}

const allEBAConfigs = [
  // VRT MAX
  {
    regexes: [
      '^https://www\\.vrt\\.be/vrtnu/',
      '^https://www\\.vrt\\.be/vrtmax/',
    ],
    config: {
      ...baseProdEBAConfig,
      ...baseVRTMAXConfig,
      snowplow_appid: 'vrtnu',
      snowplow_cookieDomain: '.www.vrt.be',
    },
  },
  {
    regexes: [
      '^https://stag\\.vrt\\.be/vrtnu/',
      '^https://stag\\.vrt\\.be/vrtmax/',
    ],
    config: {
      ...baseNonProdEBAConfig,
      ...baseVRTMAXConfig,
      environment: 'stag',
      snowplow_appid: 'vrtnu-stag',
      snowplow_cookieDomain: '.stag.vrt.be',
    },
  },
  {
    regexes: [
      '^https://dev\\.vrt\\.be/vrtnu/',
      '^https://dev\\.vrt\\.be/vrtmax/',
    ],
    config: {
      ...baseNonProdEBAConfig,
      ...baseVRTMAXConfig,
      environment: 'dev',
      snowplow_appid: 'vrtnu-dev',
      snowplow_cookieDomain: '.dev.vrt.be',
    },
  },
  {
    regexes: [
      '^https://local\\.dev\\.vrt\\.be:8000/vrtnu/',
      '^https://local\\.dev\\.vrt\\.be:8000/vrtmax/',
    ],
    config: {
      ...baseNonProdEBAConfig,
      ...baseVRTMAXConfig,
      environment: 'local',
      snowplow_appid: 'vrtnu-local',
      snowplow_cookieDomain: '.dev.vrt.be',
    },
  },
  //  ANDROME VRT MAX APPS
  {
    regexes: ['^https://www1\\.dev\\.vrtnu\\.androme\\.com/tizen/dev/'],
    config: {
      ...baseNonProdEBAConfig,
      ...baseVRTMAXTVConfig,
      environment: 'dev',
      snowplow_appid: 'vrtnu-dev',
      snowplow_cookieDomain: '.www1.dev.vrtnu.androme.com',
      platform: 'tv app',
      platformvendor: 'samsung',
    },
  },
  {
    regexes: [
      '^https://www1\\.dev\\.vrtnu\\.androme\\.com/tizen/stag/',
      '^https://samsung-tv\\.stag\\.vrt\\.be/',
    ],
    config: {
      ...baseNonProdEBAConfig,
      ...baseVRTMAXTVConfig,
      environment: 'stag',
      snowplow_appid: 'vrtnu-stag',
      snowplow_cookieDomain: '.www1.dev.vrtnu.androme.com',
      platform: 'tv app',
      platformvendor: 'samsung',
    },
  },
  {
    regexes: [
      '^https://www1\\.dev\\.vrtnu\\.androme\\.com/tizen/',
      '^https://www1\\.dev\\.vrtnu\\.androme\\.com/tizen-verify',
      '^file:///vrtmax_tizen\\.html',
      '^https://samsung-tv\\.vrt\\.be/',
    ],
    config: {
      ...baseProdEBAConfig,
      ...baseVRTMAXTVConfig,
      snowplow_appid: 'vrtnu',
      snowplow_discoverRootDomain: true,
      platform: 'tv app',
      platformvendor: 'samsung',
    },
  },
  {
    regexes: ['^https://www1\\.dev\\.vrtnu\\.androme\\.com/eos/dev/'],
    config: {
      ...baseNonProdEBAConfig,
      ...baseVRTMAXTVConfig,
      environment: 'dev',
      snowplow_appid: 'vrtnu-dev',
      snowplow_cookieDomain: '.www1.dev.vrtnu.androme.com',
      platform: 'tv app',
      platformvendor: 'telenet',
    },
  },
  {
    regexes: ['^https://www1\\.dev\\.vrtnu\\.androme\\.com/eos/stag/'],
    config: {
      ...baseNonProdEBAConfig,
      ...baseVRTMAXTVConfig,
      environment: 'stag',
      snowplow_appid: 'vrtnu-stag',
      snowplow_cookieDomain: '.www1.dev.vrtnu.androme.com',
      platform: 'tv app',
      platformvendor: 'telenet',
    },
  },
  {
    regexes: [
      '^https://www1\\.dev\\.vrtnu\\.androme\\.com/eos/',
      '^https://www1\\.dev\\.vrtnu\\.androme\\.com/eos-verify/',
      '^https://widgets\\.metrological\\.com/',
    ],
    config: {
      ...baseProdEBAConfig,
      ...baseVRTMAXTVConfig,
      snowplow_appid: 'vrtnu',
      snowplow_cookieDomain: '.widgets.metrological.com',
      platform: 'tv app',
      platformvendor: 'telenet',
    },
  },
  {
    regexes: ['^https://www1\\.dev\\.vrtnu\\.androme\\.com/webos/dev/'],
    config: {
      ...baseNonProdEBAConfig,
      ...baseVRTMAXTVConfig,
      environment: 'dev',
      snowplow_appid: 'vrtnu-dev',
      snowplow_cookieDomain: '.www1.dev.vrtnu.androme.com',
      platform: 'tv app',
      platformvendor: 'lg',
    },
  },
  {
    regexes: [
      '^https://www1\\.dev\\.vrtnu\\.androme\\.com/webos/stag/',
      '^https://lg-tv\\.stag\\.vrt\\.be/',
    ],
    config: {
      ...baseNonProdEBAConfig,
      ...baseVRTMAXTVConfig,
      environment: 'stag',
      snowplow_appid: 'vrtnu-stag',
      snowplow_cookieDomain: '.www1.dev.vrtnu.androme.com',
      platform: 'tv app',
      platformvendor: 'lg',
    },
  },
  {
    regexes: [
      '^https://www1\\.dev\\.vrtnu\\.androme\\.com/webos/',
      '^https://www1\\.dev\\.vrtnu\\.androme\\.com/webos-verify/',
      'vrtmax_webos\\.html',
      '^https://lg-tv\\.vrt\\.be/',
    ],
    config: {
      ...baseProdEBAConfig,
      ...baseVRTMAXTVConfig,
      snowplow_appid: 'vrtnu',
      snowplow_discoverRootDomain: true,
      platform: 'tv app',
      platformvendor: 'lg',
    },
  },
  {
    regexes: ['^https://www1\\.dev\\.vrtnu\\.androme\\.com/titanos/dev/'],
    config: {
      ...baseNonProdEBAConfig,
      ...baseVRTMAXTVConfig,
      environment: 'dev',
      snowplow_appid: 'vrtnu-dev',
      snowplow_cookieDomain: '.www1.dev.vrtnu.androme.com',
      platform: 'tv app',
      platformvendor: 'philips',
    },
  },
  {
    regexes: [
      '^https://www1\\.dev\\.vrtnu\\.androme\\.com/titanos/stag/',
      '^https://philips-tv\\.stag\\.vrt\\.be/',
    ],
    config: {
      ...baseNonProdEBAConfig,
      ...baseVRTMAXTVConfig,
      environment: 'stag',
      snowplow_appid: 'vrtnu-stag',
      snowplow_cookieDomain: '.www1.dev.vrtnu.androme.com',
      platform: 'tv app',
      platformvendor: 'philips',
    },
  },
  {
    regexes: [
      '^https://www1\\.dev\\.vrtnu\\.androme\\.com/titanos/',
      '^https://www1\\.dev\\.vrtnu\\.androme\\.com/titanos-verify/',
      '^https://philips-tv\\.vrt\\.be/',
    ],
    config: {
      ...baseProdEBAConfig,
      ...baseVRTMAXTVConfig,
      snowplow_appid: 'vrtnu',
      snowplow_discoverRootDomain: true,
      platform: 'tv app',
      platformvendor: 'philips',
    },
  },
  {
    regexes: ['^https://local\\.androme\\.com/'],
    config: {
      ...baseNonProdEBAConfig,
      ...baseVRTMAXTVConfig,
      environment: 'local',
      snowplow_appid: 'vrtnu-local',
      snowplow_cookieDomain: '.local.androme.com',
      platform: 'tv app',
      platformvendor: 'telenet',
    },
  },
  //  SPORZA
  {
    regexes: ['^https://stag\\.sporza\\.be/'],
    config: {
      ...baseNonProdEBAConfig,
      snowplow_enabled: true,
      ceddl_enabled: true,
      gemius_enabled: true,
      environment: 'stag',
      snowplow_appid: 'sporza-stag',
      snowplow_cookieDomain: '.stag.sporza.be',
      gemius_pageTrackingId: 'ndo7MHNOeHKitwMSegdwqpZV.l1p5qL9VOIA6In4rBj.j7',
      fallback_touchpointbrand: 'sporza',
      smartocto_enabled: true,
      smartocto_tentacles_apiToken: '17a28efc86584b5898a799d1d5ebfbef',
    },
  },
  {
    regexes: ['^https://dev\\.sporza\\.be/'],
    config: {
      ...baseNonProdEBAConfig,
      snowplow_enabled: true,
      ceddl_enabled: true,
      gemius_enabled: true,
      environment: 'dev',
      snowplow_appid: 'sporza-dev',
      snowplow_cookieDomain: '.dev.sporza.be',
      gemius_pageTrackingId: 'ndo7MHNOeHKitwMSegdwqpZV.l1p5qL9VOIA6In4rBj.j7',
      fallback_touchpointbrand: 'sporza',
      smartocto_enabled: true,
      smartocto_tentacles_apiToken: '17a28efc86584b5898a799d1d5ebfbef',
    },
  },
  {
    regexes: ['^https://sporza\\.be/', '^https://[^.]+\\.sporza\\.be/'],
    config: {
      ...baseProdEBAConfig,
      snowplow_enabled: true,
      ceddl_enabled: true,
      gemius_enabled: true,
      snowplow_appid: 'sporza',
      snowplow_cookieDomain: 'sporza.be',
      gemius_pageTrackingId: 'ndo7MHNOeHKitwMSegdwqpZV.l1p5qL9VOIA6In4rBj.j7',
      fallback_touchpointbrand: 'sporza',
      smartocto_enabled: true,
      smartocto_tentacles_apiToken: '17a28efc86584b5898a799d1d5ebfbef',
    },
  },
  {
    regexes: ['^https://local\\.dev\\.vrt\\.be:3000/vrtnws/'],
    config: {
      ...baseNonProdEBAConfig,
      ...baseNWSConfig,
      environment: 'local',
      snowplow_appid: 'vrtnws-local',
      snowplow_cookieDomain: '.dev.vrt.be',
      gemius_pageTrackingId: 'zPBAtKsjB9p5YGvD9qzmfcV2j_6ZicO4ZTfaaTu3ZvD.l7',
      smartocto_enabled: true,
      smartocto_tentacles_apiToken: '5x3sHemYzJx3wUANSHFwrLcbpX4aEd6q',
    },
  },
  {
    regexes: ['^https://dev\\.vrt\\.be/vrtnws/'],
    config: {
      ...baseNonProdEBAConfig,
      ...baseNWSConfig,
      environment: 'dev',
      snowplow_appid: 'vrtnws-dev',
      snowplow_cookieDomain: '.dev.vrt.be',
      gemius_pageTrackingId: 'zPBAtKsjB9p5YGvD9qzmfcV2j_6ZicO4ZTfaaTu3ZvD.l7',
      smartocto_enabled: true,
      smartocto_tentacles_apiToken: '5x3sHemYzJx3wUANSHFwrLcbpX4aEd6q',
    },
  },
  {
    regexes: ['^https://stag\\.vrt\\.be/vrtnws/'],
    config: {
      ...baseNonProdEBAConfig,
      ...baseNWSConfig,
      environment: 'stag',
      snowplow_appid: 'vrtnws-stag',
      snowplow_cookieDomain: '.stag.vrt.be',
      gemius_pageTrackingId: 'zPBAtKsjB9p5YGvD9qzmfcV2j_6ZicO4ZTfaaTu3ZvD.l7',
      smartocto_enabled: true,
      smartocto_tentacles_apiToken: '5x3sHemYzJx3wUANSHFwrLcbpX4aEd6q',
    },
  },
  {
    regexes: ['^https://www\\.vrt\\.be/vrtnws/'],
    config: {
      ...baseProdEBAConfig,
      ...baseNWSConfig,
      snowplow_appid: 'vrtnws',
      snowplow_cookieDomain: '.www.vrt.be',
      gemius_pageTrackingId: 'zPBAtKsjB9p5YGvD9qzmfcV2j_6ZicO4ZTfaaTu3ZvD.l7',
      smartocto_enabled: true,
      smartocto_tentacles_apiToken: '5x3sHemYzJx3wUANSHFwrLcbpX4aEd6q',
    },
  },
  {
    regexes: ['^https://local\\.dev\\.vrt\\.be:3000/nwsnwsnws/'],
    config: {
      ...baseNonProdEBAConfig,
      ...baseNWSNWSNWSConfig,
      environment: 'local',
      snowplow_appid: 'nwsnwsnws-local',
      snowplow_cookieDomain: '.dev.vrt.be',
      gemius_pageTrackingId: 'pzBKgbN_c0_ZX9z9tT6A3Les.Ik8z6SFLeIPZUaOWrT.I7',
    },
  },
  {
    regexes: ['^https://dev\\.vrt\\.be/nwsnwsnws/'],
    config: {
      ...baseNonProdEBAConfig,
      ...baseNWSNWSNWSConfig,
      environment: 'dev',
      snowplow_appid: 'nwsnwsnws-dev',
      snowplow_cookieDomain: '.dev.vrt.be',
      gemius_pageTrackingId: 'pzBKgbN_c0_ZX9z9tT6A3Les.Ik8z6SFLeIPZUaOWrT.I7',
    },
  },
  {
    regexes: ['^https://stag\\.vrt\\.be/nwsnwsnws/'],
    config: {
      ...baseNonProdEBAConfig,
      ...baseNWSNWSNWSConfig,
      environment: 'stag',
      snowplow_appid: 'nwsnwsnws-stag',
      snowplow_cookieDomain: '.stag.vrt.be',
      gemius_pageTrackingId: 'pzBKgbN_c0_ZX9z9tT6A3Les.Ik8z6SFLeIPZUaOWrT.I7',
    },
  },
  {
    regexes: ['^https://www\\.vrt\\.be/nwsnwsnws/'],
    config: {
      ...baseProdEBAConfig,
      ...baseNWSNWSNWSConfig,
      snowplow_appid: 'nwsnwsnws',
      snowplow_cookieDomain: '.www.vrt.be',
      gemius_pageTrackingId: 'pzBKgbN_c0_ZX9z9tT6A3Les.Ik8z6SFLeIPZUaOWrT.I7',
    },
  },
  {
    regexes: ['^https://mediaplayer-cast-web-receiver\\.prod\\.a51\\.be/'],
    config: {
      ...baseProdEBAConfig,
      ...baseChromecastConfig,
      snowplow_appid: 'chromecast',
    },
  },
  {
    regexes: ['^https://mediaplayer-cast-web-receiver-beta\\.prod\\.a51\\.be/'],
    config: {
      ...baseNonProdEBAConfig,
      ...baseChromecastConfig,
      environment: 'stag',
      snowplow_appid: 'chromecast-beta',
    },
  },
  {
    regexes: ['^https://mediaplayer-cast-web-receiver\\.stag\\.a51\\.be/'],
    config: {
      ...baseNonProdEBAConfig,
      ...baseChromecastConfig,
      environment: 'stag',
      snowplow_appid: 'chromecast-stag',
    },
  },
  {
    regexes: ['^https://mediaplayer-cast-web-receiver\\.dev\\.a51\\.be/'],
    config: {
      ...baseNonProdEBAConfig,
      ...baseChromecastConfig,
      environment: 'dev',
      snowplow_appid: 'chromecast-dev',
    },
  },
  {
    regexes: [
      '^https://vrtcast\\.eu\\.ngrok\\.io/',
      '^https://vrt\\.beeles-place\\.be:3000/',
    ],
    config: {
      ...baseNonProdEBAConfig,
      ...baseVRTMAXConfig,
      ...baseChromecastConfig,
      environment: 'local',
      snowplow_appid: 'chromecast-local',
    },
  },
  {
    regexes: ['^http://t-local\\.vrt\\.be:8080/'],
    config: {
      ...baseNonProdEBAConfig,
      environment: 'local',
      snowplow_appid: 't-local',
      gemius_pageTrackingId: '.RCVVir7bQn.P.7HJrzsmXa4TBkBtcS0ujAy79zfGkD.r7', // TODO : better id? is corporate site id
      snowplow_cookieDomain: '.t-local.vrt.be',
      ceddl_enabled: true,
      gemius_enabled: true,
      in_app: !!(typeof window !== 'undefined' && window.Flutter),
      snowplow_aggressiveCleanup: true,
      smartocto_enabled: true,
      smartocto_tentacles_apiToken: '5x3sHemYzJx3wUANSHFwrLcbpX4aEd6q',
    },
  },
  {
    regexes: ['^https://t-stag\\.vrt\\.be/'],
    config: {
      ...baseNonProdEBAConfig,
      snowplow_collectorUrl: 'https://web-collector-dev.datascience.a51.be',
      environment: 'stag',
      snowplow_appid: 't-stag',
      gemius_pageTrackingId: '.RCVVir7bQn.P.7HJrzsmXa4TBkBtcS0ujAy79zfGkD.r7', // TODO : better id? is corporate site id
      snowplow_cookieDomain: '.t-stag.vrt.be',
      ceddl_enabled: true,
      gemius_enabled: true,
    },
  },
  {
    regexes: ['^https://t\\.vrt\\.be/'],
    config: {
      ...baseProdEBAConfig,
      snowplow_appid: 't',
      gemius_pageTrackingId: '.RCVVir7bQn.P.7HJrzsmXa4TBkBtcS0ujAy79zfGkD.r7', // TODO : better id? is corporate site id
      snowplow_cookieDomain: '.t.vrt.be',
      ceddl_enabled: true,
      gemius_enabled: true,
    },
  },
  {
    regexes: [
      '^https://player[^.]*\\.vrt\\.be/',
      '^https://local\\.vrt\\.be/docs',
    ],
    config: {
      ...baseNonProdEBAConfig,
      environment: 'unknown',
      snowplow_appid: 'playertest',
      snowplow_discoverRootDomain: true,
      gemius_pageTrackingId: '.RCVVir7bQn.P.7HJrzsmXa4TBkBtcS0ujAy79zfGkD.r7', // TODO : better id? is corporate site id
    },
  },
  {
    regexes: [
      '^https://stag\\.vrt\\.be/interactie/feed/',
      '^https://dev\\.vrt\\.be/interactie/feed/',
    ],
    config: {
      ...baseNonProdEBAConfig,
      ceddl_initialPage: {
        channel: 'web',
        page_technology: 'single page',
        brand_media: 'ketnet',
        brand_technology: 'vrt nu',
      },
      snowplow_appid: 'interactie-feed-stag',
      snowplow_discoverRootDomain: true,
      ceddl_enabled: true,
      fallback_touchpointbrand: 'vrt max',
    },
  },
  {
    regexes: ['^https://www\\.vrt\\.be/interactie/feed/'],
    config: {
      ...baseProdEBAConfig,
      ceddl_initialPage: {
        channel: 'web',
        page_technology: 'single page',
        brand_media: 'ketnet',
        brand_technology: 'vrt nu',
      },
      snowplow_appid: 'interactie-feed',
      snowplow_discoverRootDomain: true,
      ceddl_enabled: true,
      fallback_touchpointbrand: 'vrt max',
    },
  },
  {
    regexes: [
      '^https://stag\\.vrt\\.be/interactie/',
      '^https://dev\\.vrt\\.be/interactie/',
      '^https://local\\.stag\\.vrt\\.be/interactie/',
      '^https://local\\.dev\\.vrt\\.be/interactie/',
    ],
    config: {
      ...baseNonProdEBAConfig,
      ceddl_initialPage: {
        channel: 'web',
        page_technology: 'single page',
        brand_media: 'vrt nu',
        brand_technology: 'vrt nu',
      },
      snowplow_appid: 'interactie-stem-stag',
      snowplow_discoverRootDomain: true,
      ceddl_enabled: true,
      fallback_touchpointbrand: 'vrt max',
    },
  },
  {
    regexes: ['^https://www\\.vrt\\.be/interactie/'],
    config: {
      ...baseProdEBAConfig,
      ceddl_initialPage: {
        channel: 'web',
        page_technology: 'single page',
        brand_media: 'vrt nu',
        brand_technology: 'vrt nu',
      },
      snowplow_appid: 'interactie-stem',
      snowplow_discoverRootDomain: true,
      ceddl_enabled: true,
      fallback_touchpointbrand: 'vrt max',
    },
  },
  {
    regexes: ['^https://embed\\.vrtopenmedia\\.be/'],
    config: {
      ...baseProdEBAConfig,
      snowplow_appid: 'open-media',
      snowplow_discoverRootDomain: true,
      ceddl_enabled: true,
      fallback_touchpointbrand: 'vrt open media',
    },
  },
  {
    regexes: [
      '^https://embed-stag\\.vrtopenmedia\\.be/',
      '^https://local\\.vrtopenmedia\\.be',
    ],
    config: {
      ...baseNonProdEBAConfig,
      environment: 'stag',
      snowplow_appid: 'open-media-stag',
      snowplow_discoverRootDomain: true,
      ceddl_enabled: true,
      fallback_touchpointbrand: 'vrt open media',
    },
  },
  {
    regexes: [
      '^https://edubox\\.vrtnws\\.be',
      '^https://onderwijs\\.vrt\\.be',
      '^https://edubox-[^\\.]+\\.vrtnws\\.be',
    ],
    config: {
      ...baseProdEBAConfig,
      environment: 'prod',
      snowplow_appid: 'edubox',
      snowplow_discoverRootDomain: true,
      ceddl_enabled: true,
      fallback_touchpointbrand: 'vrt',
    },
  },
  {
    regexes: ['^https://onderwijs-stag\\.vrt\\.be'],
    config: {
      ...baseNonProdEBAConfig,
      environment: 'stag',
      snowplow_appid: 'edubox',
      snowplow_discoverRootDomain: true,
      ceddl_enabled: true,
      fallback_touchpointbrand: 'vrt',
    },
  },
  // DAGELIJKS KOST
  {
    regexes: [
      '^https://preview\\.dev\\.dagelijksekost\\.tv',
      '^https://preview\\.stag\\.dagelijksekost\\.tv',
    ],
    config: {
      ...baseNonProdEBAConfig,
      ceddl_initialPage: {
        channel: 'web',
        brand_media: 'dagelijkse kost',
        brand_technology: 'dagelijkse kost',
      },
      snowplow_appid: 'dako-stag',
      snowplow_discoverRootDomain: true,
      ceddl_enabled: true,
      fallback_touchpointbrand: 'dagelijkse kost',
    },
  },
  {
    regexes: [
      '^https://dagelijksekost\\.een\\.be',
      '^https://dagelijksekost\\.vrt\\.be',
    ],
    config: {
      ...baseProdEBAConfig,
      snowplow_appid: 'adobelaunchbased-prod',
      snowplow_discoverRootDomain: true,
      gemius_enabled: true,
      gemius_pageTrackingId: 'zZ01ZiOjFcRSaBztUl0z0XZGj1iB8kMrEFRduqZNJT3.W7',
      ceddl_enabled: false,
      snowplow_enabled: true,
      tms_autotagging: true,
      fallback_touchpointbrand: 'dagelijkse kost',
    },
  },
  // RADIO 1
  {
    regexes: ['^https://radio1\\.be'],
    config: {
      ...baseProdEBAConfig,
      snowplow_appid: 'adobelaunchbased-prod',
      snowplow_discoverRootDomain: true,
      gemius_enabled: false,
      ceddl_enabled: false,
      snowplow_enabled: true,
      tms_autotagging: true,
      fallback_touchpointbrand: 'radio 1',
    },
  },
  // RADIO 2
  {
    regexes: ['^https://radio2\\.be'],
    config: {
      ...baseProdEBAConfig,
      snowplow_appid: 'adobelaunchbased-prod',
      snowplow_discoverRootDomain: true,
      gemius_enabled: false,
      ceddl_enabled: false,
      snowplow_enabled: true,
      tms_autotagging: true,
      fallback_touchpointbrand: 'radio 2',
    },
  },
  // DE WARMSTE WEEK
  {
    regexes: [
      '^https://dewarmsteweek\\.be',
      '^https://mijnwarmsteweek\\.dewarmsteweek\\.be',
    ],
    config: {
      ...baseProdEBAConfig,
      snowplow_appid: 'adobelaunchbased-prod',
      snowplow_discoverRootDomain: true,
      gemius_enabled: false,
      ceddl_enabled: false,
      snowplow_enabled: true,
      tms_autotagging: true,
      fallback_touchpointbrand: 'de warmste week',
    },
  },
  // VRT JOBS
  {
    regexes: ['^https://jobs\\.vrt\\.be'],
    config: {
      ...baseProdEBAConfig,
      snowplow_appid: 'adobelaunchbased-prod',
      snowplow_discoverRootDomain: true,
      gemius_enabled: false,
      ceddl_enabled: false,
      snowplow_enabled: true,
      tms_autotagging: true,
      fallback_touchpointbrand: 'vrt jobs',
    },
  },
  // VRT INTERNATIONAL
  {
    regexes: ['^https://www\\.vrtinternational\\.com'],
    config: {
      ...baseProdEBAConfig,
      snowplow_appid: 'adobelaunchbased-prod',
      snowplow_discoverRootDomain: true,
      gemius_enabled: false,
      ceddl_enabled: false,
      snowplow_enabled: true,
      tms_autotagging: true,
      fallback_touchpointbrand: 'vrt international',
    },
  },
  // SPORZA
  {
    regexes: [
      '^https://wielermanager\\.sporza\\.be',
      '^https://wielermanager\\.sporza\\.be',
    ],
    config: {
      ...baseProdEBAConfig,
      snowplow_appid: 'adobelaunchbased-prod',
      snowplow_discoverRootDomain: true,
      gemius_enabled: false,
      ceddl_enabled: false,
      snowplow_enabled: true,
      tms_autotagging: true,
      fallback_touchpointbrand: 'sporza',
    },
  },
  // KETNET
  {
    regexes: ['^https://stag\\.ketnet\\.be'],
    config: {
      ...baseNonProdEBAConfig,
      environment: 'stag',
      snowplow_appid: 'adobelaunchbased-stag',
      snowplow_discoverRootDomain: true,
      gemius_enabled: false,
      ceddl_enabled: false,
      snowplow_enabled: true,
      tms_autotagging: true,
      fallback_touchpointbrand: 'ketnet',
    },
  },
  {
    regexes: ['^https://www\\.ketnet\\.be'],
    config: {
      ...baseProdEBAConfig,
      snowplow_appid: 'adobelaunchbased-prod',
      snowplow_discoverRootDomain: true,
      gemius_enabled: false,
      ceddl_enabled: false,
      snowplow_enabled: true,
      tms_autotagging: true,
      fallback_touchpointbrand: 'ketnet',
    },
  },
  // KETNET OUDERS
  {
    regexes: ['^https://ouders\\.ketnet\\.be'],
    config: {
      ...baseProdEBAConfig,
      snowplow_appid: 'adobelaunchbased-prod',
      snowplow_discoverRootDomain: true,
      gemius_enabled: false,
      ceddl_enabled: false,
      snowplow_enabled: true,
      tms_autotagging: true,
      fallback_touchpointbrand: 'ketnet ouders',
    },
  },
  // VRT PROFIEL
  {
    regexes: ['^https://profiel\\.vrt\\.be', '^https://login\\.vrt\\.be'],
    config: {
      ...baseProdEBAConfig,
      snowplow_appid: 'adobelaunchbased-prod',
      snowplow_discoverRootDomain: true,
      gemius_enabled: false,
      ceddl_enabled: false,
      snowplow_enabled: true,
      tms_autotagging: true,
    },
  },
  {
    regexes: [
      '^https://profiel-stag\\.vrt\\.be',
      '^https://login-stag\\.vrt\\.be',
    ],
    config: {
      ...baseNonProdEBAConfig,
      fallback_touchpointbrand: 'vrt',
      environment: 'stag',
      snowplow_appid: 'vrt-profiel-stag',
    },
  },
  // VRT CORPORATE
  {
    regexes: ['^https://www\\.vrt\\.be/nl'],
    config: {
      ...baseProdEBAConfig,
      snowplow_appid: 'prod',
      fallback_touchpointbrand: 'vrt corporate',
      snowplow_discoverRootDomain: true,
      gemius_enabled: false,
      ceddl_enabled: false,
      snowplow_enabled: true,
    },
  },
  {
    regexes: ['^https://stag\\.vrt\\.be'],
    config: {
      ...baseNonProdEBAConfig,
      environment: 'stag',
      snowplow_appid: 'stag',
      fallback_touchpointbrand: 'vrt corporate',
      snowplow_discoverRootDomain: true,
      gemius_enabled: false,
      ceddl_enabled: false,
      snowplow_enabled: true,
    },
  },
  {
    regexes: ['^https://prod\\.vrt\\.hosted-temp\\.com'], // new website stag (change old config after go live)
    config: {
      ...baseNonProdEBAConfig,
      environment: 'stag',
      snowplow_appid: 'stag',
      snowplow_discoverRootDomain: true,
      gemius_enabled: false,
      ceddl_enabled: false,
      snowplow_enabled: true,
    },
  },
  {
    regexes: ['^https://dev\\.vrt\\.be'],
    config: {
      ...baseNonProdEBAConfig,
      environment: 'dev',
      snowplow_appid: 'adobelaunchbased-dev',
      snowplow_discoverRootDomain: true,
      gemius_enabled: false,
      ceddl_enabled: false,
      snowplow_enabled: true,
      tms_autotagging: true,
    },
  },
  {
    regexes: ['^http'],
    config: {
      ...baseNonProdEBAConfig,
      environment: 'unknown',
      snowplow_appid: 'unknown',
      snowplow_discoverRootDomain: true,
      gemius_pageTrackingId: '.RCVVir7bQn.P.7HJrzsmXa4TBkBtcS0ujAy79zfGkD.r7', // TODO : better id? is corporate site id
    },
  },
]

function findConfigByHref(href) {
  const { config } = allEBAConfigs.find((cfg) => {
    const regex = new RegExp(cfg.regexes.join('|'))
    return regex.test(href)
  })
  return config
}

export default findConfigByHref
