import logger from 'loglevel'
import EBAConfig from '../EBAConfig/EBAConfig'
import { formatDate, parseContexts } from '../utils'

function CEDDLSubscriberFactory() {
  let installed = false
  const stats = {
    eventsProcessed: 0,
    eventsDelivered: 0,
    eventsFailed: 0,
  }
  const validDateRegex = /^\d{4}-\d{2}-\d{2}$/

  const handleEvent = (ev) => {
    const { ctxes } = parseContexts(ev)

    if (
      ev.event.schema.startsWith('iglu:be.vrt/page_impression/jsonschema/1-')
    ) {
      const page = window.digitalData.page || {}
      const user = window.digitalData.user || {}
      const media = window.digitalData.media || {}

      // generic properties
      page.brand_media = ctxes?.context_page?.contentbrand
      page.brand_technology = ctxes?.context_page?.touchpointbrand
      page.channel = ctxes?.context_platform?.platform
      page.environment = ctxes?.context_platform?.environment
      page.page_domain = ctxes?.context_page?.domain
      page.page_id = ctxes?.context_page?.pageid
      page.page_language = ctxes?.context_page?.pagelanguage
      page.page_title = ctxes?.context_page?.pagetitle
      page.page_name = ctxes?.context_page?.pagename
      page.page_uri = ctxes?.context_page?.pagepath
      page.page_url = ctxes?.context_page?.pageurl
      page.label = ctxes?.context_page?.labels
      page.siteSection_1 = ctxes?.context_page?.sitesection1
      page.siteSection_2 = ctxes?.context_page?.sitesection2
      page.siteSection_3 = ctxes?.context_page?.sitesection3
      page.siteSection_4 = ctxes?.context_page?.sitesection4

      // article properties
      page.articleType = ctxes?.context_content_article?.articletype
      page.article_author = ctxes?.context_content_article?.articleauthor
      page.article_region = ctxes?.context_content_news?.newsregion
      page.article_video = ctxes?.context_content_article?.articlevideocount
      page.article_wordCount = ctxes?.context_content_article?.articlewordcount
      if (ctxes?.context_content_article?.publishtimestamp) {
        const { date, hour } = formatDate(
          ctxes.context_content_article.publishtimestamp,
        )
        if (date.match(validDateRegex)) page.publishDate = date
        page.publishHour = hour
      } else {
        page.publishDate = null
        page.publishHour = null
      }

      if (ctxes?.context_content_article?.updatetimestamp) {
        const { date, hour } = formatDate(
          ctxes.context_content_article.updatetimestamp,
        )
        if (date.match(validDateRegex)) page.updateDate = date
        page.updateHour = hour
      } else {
        page.updateDate = null
        page.updateHour = null
      }

      // media content properties
      page.program_name =
        ctxes?.context_content_program?.programname?.toLowerCase()
      page.program_whatsonId = ctxes?.context_content_program?.catalogid
      page.episode_season = ctxes?.context_content_season?.seasonname
      page.episode_id = ctxes?.context_content_episode?.episodenumber
      page.episode_whatsonId = ctxes?.context_content_episode?.catalogid
      page.episode_name = ctxes?.context_content_episode?.episodename

      if (ctxes?.context_content_episode?.publishtimestamp) {
        const airDate = formatDate(
          ctxes.context_content_episode.publishtimestamp,
          { dateOnly: true },
        )
        if (airDate?.match(validDateRegex)) {
          page.episode_broadcast_date = airDate
          page.publishDate = airDate
        }
      } else {
        page.episode_broadcast_date = null
        page.publishDate = page.publishDate || null
      }

      // debugger
      page.debugger_code = ctxes?.context_debugger?.debuggercode || null

      // user properties
      user.marketingId = ctxes?.context_user?.marketingid
      user.profiletype = ctxes?.context_user?.profiletype

      // TODO : improve
      const pageType = ctxes?.context_page?.pagetype
      if (pageType === 'podcastepisodepage') {
        media.media_subtype = 'podcast'
      } else if (pageType === 'radioepisodepage') {
        media.media_subtype = 'radio programma'
      } else {
        media.media_subtype = null
      }

      window.digitalData.page = page
      window.digitalData.user = user
      window.digitalData.media = media

      document.body.dispatchEvent(new CustomEvent('trackpageview'))
    }
  }

  const pushEventToCEDDL = (ev) => {
    logger.debug('EBA: pushEventToCEDDL')
    logger.debug(JSON.stringify(ev))
    stats.eventsProcessed += 1
    try {
      handleEvent(ev)
      stats.eventsDelivered += 1
    } catch (e) {
      stats.eventsFailed += 1
      throw e
    }
  }

  const install = () => {
    try {
      if (EBAConfig.ceddl_enabled && !installed) {
        window.digitalData.events.push({ subscribe: pushEventToCEDDL })
        installed = true
      }
    } catch (_e) {
      logger.warn('EBA: failed to install CEDDLSubscriber')
    }
  }

  const uninstall = () => {
    logger.debug('EBA CEDDLSubscriber: uninstalling')
  }

  return {
    install,
    uninstall,
    stats,
  }
}

export default CEDDLSubscriberFactory()
