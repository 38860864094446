import CMPHelper from '../CMP/CMPHelper'
import EBAConfig from '../EBAConfig/EBAConfig'
import SSOHelper from '../SSO/SSOHelper'

function EDDLEnricherFactory() {
  let cachedPageContext = null
  let cachedPlatformContext = null
  let cachedDeviceContext = null
  let cachedBrowserContext = null
  let touchpointbrandOverride = null

  // ================
  //  hoisted publics
  // ================
  const getTouchpointbrand = () =>
    touchpointbrandOverride || EBAConfig.fallback_touchpointbrand || 'vrt'

  const setTouchpointbrand = (touchpointbrand) => {
    touchpointbrandOverride = touchpointbrand
    if (cachedPlatformContext) {
      cachedPlatformContext.data.touchpointbrand = touchpointbrand
    }
  }

  // =========
  // privates
  // =========
  const addContext = (ev, aContext) => {
    const event = ev
    if (!event.context) event.context = []
    ev.context.push(aContext)
  }

  const addConsentContext = (ev) => {
    const consent = CMPHelper.getConsents()
    if (consent) {
      const consentContext = {
        schema: 'iglu:be.vrt/context_consent/jsonschema/1-1-0',
        data: { ...consent },
      }
      addContext(ev, consentContext)
    }
  }

  function getChildLoginFlowID() {
    const id = window.VRT?.UserServices?.getCorrelationId?.()
    return id || null
  }

  const addUserContext = (ev) => {
    const mid = SSOHelper.getMarketingID()
    const pty = SSOHelper.getProfileType()
    //        const ecid = AdobeHelper.getECID();
    const clfid = getChildLoginFlowID()
    if (mid || pty || clfid) {
      const userContext = {
        schema: 'iglu:be.vrt/context_user/jsonschema/1-2-0',
        data: {},
      }
      if (mid) userContext.data.marketingid = mid
      if (pty) userContext.data.profiletype = pty
      //            if (ecid) userContext.data.ecid = ecid;
      if (clfid) userContext.data.childloginflowid = clfid
      addContext(ev, userContext)
    }
  }

  const findContext = (ev, schemaStartsWith) => {
    if (ev.context)
      return ev.context.find((el) => el.schema.startsWith(schemaStartsWith))
    return null
  }

  const addPageContext = (ev) => {
    const pageContext = findContext(ev, 'iglu:be.vrt/context_page/')
    if (!pageContext) {
      if (cachedPageContext) addContext(ev, cachedPageContext)
    } else {
      cachedPageContext = { ...pageContext }
    }
  }

  const addDebuggingContext = (ev, code) => {
    if (code) {
      const debuggingContext = {
        schema: 'iglu:be.vrt/context_debugger/jsonschema/1-1-0',
        data: { debuggercode: code },
      }
      addContext(ev, debuggingContext)
    }
  }

  const addPlatformContext = (ev) => {
    const platformContext = findContext(ev, 'iglu:be.vrt/context_platform/')
    if (!platformContext) {
      if (cachedPlatformContext) addContext(ev, cachedPlatformContext)
    } else {
      cachedPlatformContext = { ...platformContext }
    }
  }

  const initPlatformContext = () => {
    cachedPlatformContext = {
      schema: 'iglu:be.vrt/context_platform/jsonschema/1-2-2',
      data: {
        platform: EBAConfig.platform || 'web',
        environment: EBAConfig.environment || 'unknown',
        touchpointbrand: getTouchpointbrand(),
      },
    }
    if (EBAConfig.platformvendor)
      cachedPlatformContext.data.platformvendor = EBAConfig.platformvendor
    if (EBAConfig.platformtechnology)
      cachedPlatformContext.data.platformtechnology =
        EBAConfig.platformtechnology
  }

  const initDeviceContext = () => {
    let screensize
    if (window?.screen?.height && window?.devicePixelRatio) {
      const height = Math.round(window.screen.height * window.devicePixelRatio)
      const width = Math.round(window.screen.width * window.devicePixelRatio)
      screensize = `${width}x${height}`
    }

    cachedDeviceContext = {
      schema: 'iglu:be.vrt/context_device/jsonschema/1-2-0',
      data: {
        operatingsystem:
          window.navigator.userAgentData?.platform ||
          window?.navigator.platform ||
          'unknown',
        operatingsystemversion: 'unknown',
        screensize,
        connectionstate: window.navigator?.onLine ? 'online' : 'offline',
      },
    }
  }

  const initBrowserContext = () => {
    const browserData = window.navigator.userAgentData?.brands
      ?.filter((e) => !/brand/i.test(e.brand))
      ?.reverse()?.[0]
    cachedBrowserContext = {
      schema: 'iglu:be.vrt/context_browser/jsonschema/1-1-0',
      data: {
        useragent: window.navigator?.userAgent || 'unknown',
        browserlanguage:
          window.navigator?.language?.substring(0, 2) || 'unknown',
        browsertype: browserData?.brand || 'unknown',
        browserversion: browserData?.version || 'unknown',
      },
    }
    if (window.navigator?.cookieEnabled !== undefined)
      cachedBrowserContext.data.cookiesallowed = window.navigator.cookieEnabled
  }

  const initPageContext = () => {
    cachedPageContext = {
      schema: 'iglu:be.vrt/context_page/jsonschema/1-1-0',
      data: {
        pagename: 'fallback',
        pageurl: window.location.origin + window.location.pathname,
        touchpointbrand: EBAConfig.fallback_touchpointbrand || 'vrt',
        contentbrand: 'vrt',
        pagetitle: document.title,
        domain: window.location.hostname,
        pagepath: window.location.pathname,
      },
    }
  }

  initPlatformContext()
  initDeviceContext()
  initBrowserContext()
  initPageContext()

  // =========
  // publics
  // =========
  const enrichEvent = (ev, options = {}) => {
    addConsentContext(ev)
    addUserContext(ev)
    addPageContext(ev)
    addPlatformContext(ev)
    addContext(ev, cachedDeviceContext)
    addContext(ev, cachedBrowserContext)
    if (options.remoteDebuggingCode)
      addDebuggingContext(ev, options.remoteDebuggingCode)
    return ev
  }

  return {
    enrichEvent,
    setTouchpointbrand,
    getTouchpointbrand,
  }
}

export default EDDLEnricherFactory()
