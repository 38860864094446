import findConfigByHref from './allConfigs'

/* This is the web config derived from the general config in allConfigs
   Apply general tweaks in allConfig
   Apply web config tweaks in this file.
   Apply app config tweaks in generateAppConfig */

const config = findConfigByHref(document.location.href)

if (config.ceddl_initialPage) {
  config.ceddl_initialPage.page_domain =
    config.ceddl_initialPage?.page_domain || window.location.hostname
}

export default config
