export const formatDate = (timestamp, { dateOnly, timeOnly, toISO } = {}) => {
  if (!timestamp) return undefined

  const date = new Date(timestamp)

  if (timeOnly) {
    return date.toLocaleTimeString('fr-CA').substring(0, 2) // Returns hour only
  }

  if (dateOnly) {
    return date.toLocaleDateString('fr-CA') // Returns YYYY-MM-DD
  }

  if (toISO) {
    return date.toISOString()
  }

  // Returns both date and hour
  const dateStr = date.toLocaleDateString('fr-CA')
  const hourStr = date.toLocaleTimeString('fr-CA').substring(0, 2)
  return { date: dateStr, hour: hourStr }
}

export default formatDate
